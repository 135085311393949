// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // ApiUrl:'http://localhost/vcip_back/api/api.php',
  // imgBasePath:'http://localhost/vcip_back',
  ApiUrl:'https://vcip-api.avsstech.org/api/api.php',
  imgBasePath:'https://vcip-api.avsstech.org',
  Authorization:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvdmVyaWZ5LmxpdmVtZWV0dXBzLm5ldCIsImF1ZCI6Imh0dHA6XC9cL2xvY2FsaG9zdCIsImlhdCI6MTU5MjgxMDY1NCwibmJmIjoxNTkyODEwNjY0LCJleHAiOjE1OTI4MTA5NTQsImRhdGEiOnsiaWQiOiIxIiwidXNlcm5hbWUiOiJoYWlkYXIiLCJlbWFpbCI6ImhhaWRhckBnbWFpbC5jb20ifX0.Iy7owbsBwyTKGYjVGqbEQ07zq-VvSg3eZDVYSn0GQzs',
  password:'123456'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
